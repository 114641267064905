import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Checkbox, TableCell, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../common/button/FanGoIconButton';
import CustomFanGoDialog from '../../common/dialog/CustomFanGoDialog';
import CustomDialogContent from './dialogContent/CustomDialogContent';
import { DraggableTableRow } from './StyledTableComponents';
import TableCellInputs from './TableCellInputs';
import { handleDeleteRow } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      textAlign: 'center',
      margin: 'auto',
    },
    tableCell: {
      textAlign: 'center !important',
    },
  })
);

const EventPlanningTableRow = ({
  columns,
  index,
  rowUid,
  quickRowDeletionEnabled,
  eventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptionsOrder,
  setEventPlanningOptions,
  section,
  sections,
  setSections,
}) => {
  const classes = useStyles();
  const { handleChange, values } = useFormikContext();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');

  const handleShowDialog = ({ type }) => {
    setDialogContentType(type);
    setShowDialog(true);
  };

  const handleDeleteClick = () => {
    if (quickRowDeletionEnabled) {
      handleDeleteRow({
        rowUid,
        setShowDialog,
        eventPlanningOptionsOrder,
        setEventPlanningOptionsOrder,
        eventPlanningOptions,
        setEventPlanningOptions,
        section,
        sections,
        setSections,
      });
    } else {
      handleShowDialog({ type: 'delete' });
    }
  };

  const { rowTitle, draggable, editable, deletable, titleRow } = values[rowUid];

  return (
    <>
      {showDialog && (
        <CustomFanGoDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <CustomDialogContent
            type={dialogContentType}
            columns={columns}
            rowUid={rowUid}
            setShowDialog={setShowDialog}
            eventPlanningOptionsOrder={eventPlanningOptionsOrder}
            setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
            eventPlanningOptions={eventPlanningOptions}
            setEventPlanningOptions={setEventPlanningOptions}
            section={section}
            sections={sections}
            setSections={setSections}
          />
        </CustomFanGoDialog>
      )}

      {!titleRow && (
        <Draggable
          key={rowUid}
          draggableId={rowUid}
          index={index}
          isDragDisabled={!draggable}
        >
          {(provided, snapshot) => (
            <DraggableTableRow
              provided={provided}
              isDragging={snapshot.isDragging}
              titleRow={titleRow}
            >
              <TableCell {...provided.dragHandleProps}>
                <Box sx={{ display: 'flex' }}>
                  {draggable && <DragIndicatorIcon />}
                </Box>
              </TableCell>

              <TableCell>
                <Typography variant={titleRow ? 'h5' : null}>
                  {rowTitle}
                </Typography>
              </TableCell>

              {columns.map((column) => {
                const { id, inputOptions } = column;
                const uid = `${rowUid}-${id}`;
                if (!!inputOptions && !titleRow) {
                  const inputOption =
                    values?.[rowUid]?.[id]?.[`${id}ActiveInputOption`];
                  return (
                    <TableCell key={uid} className={classes.tableCell}>
                      {TableCellInputs({
                        inputOption: inputOption,
                        rowUid,
                        columnId: id,
                      })}
                    </TableCell>
                  );
                } else if (titleRow && inputOptions) {
                  return <TableCell key={uid} />;
                }
              })}

              <TableCell className={classes.tableCell}>
                {editable && (
                  <FanGoIconButton
                    type="edit"
                    size="medium"
                    onClick={() =>
                      handleShowDialog({
                        type: titleRow ? 'editTitleRow' : 'edit',
                      })
                    }
                    disabled={!editable}
                  />
                )}
              </TableCell>

              <TableCell className={classes.tableCell}>
                {deletable && (
                  <FanGoIconButton
                    type="delete"
                    size="medium"
                    onClick={handleDeleteClick}
                    disabled={!deletable}
                  />
                )}
              </TableCell>
            </DraggableTableRow>
          )}
        </Draggable>
      )}
    </>
  );
};

export default EventPlanningTableRow;
