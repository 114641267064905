import React from 'react';

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 5,
    backgroundColor: '#141854',
    color: 'white',
    alignItems: 'center',
  },
  doneTableCell: {
    width: '10%',
    paddingLeft: 10,
  },
  tableCell: {
    width: '30%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    textAlign: 'center',
  },
  emptyCell: {
    padding: 1,
    width: '40%',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  fanGoLogoAndTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  imageContainer: {
    height: 50,
    backgroundColor: '#141854',
    borderRadius: 10,
    padding: 5,
    marginRight: 3,
  },
  fangoText: {},
  image: {
    height: '100%',
  },
});

const PageFooter = ({ numberOfPages, currentPage, logo }) => {
  const pageNumberText = `Page ${currentPage + 1}/${numberOfPages}`;
  let img = null;
  if (logo) {
    const { asset } = logo;
    const { url } = asset;
    img = url;
  }

  return (
    <View fixed style={styles.footer}>
      <View style={styles.fanGoLogoAndTextContainer}>
        {img && (
          <View style={styles.imageContainer}>
            <Image src={img} style={styles.image} />
          </View>
        )}
        {/* <Text style={styles.fangoText}>FanGo Ltd</Text> */}
      </View>
      <Text>{pageNumberText}</Text>
    </View>
  );
};

export default PageFooter;
