import React from 'react';

import { Font, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';

import TableHeaders from './TableHeaders';

// Font.register({ family: 'normal', fontWeight: 'bold' });
Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 'bold',
    },
  ],
});

const styles = (props?: any) =>
  StyleSheet.create({
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: props.titleRow
        ? null
        : props.index % 2
        ? 'rgb(245, 245, 245)'
        : 'white',
      borderBottom: '1px solid lightgray',
    },
    rowTitleContainer: {
      width: '30%',
      paddingLeft: 3,
      paddingTop: 3,
      paddingBottom: 3,
    },
    titleRowContainer: {
      backgroundColor: props.backgroundColour,
      color: props.textColour,
      width: '100%',
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 3,
    },
    rowTitleText: {},
    tableCell: {
      width: '23%',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textboxContainer: {
      width: '23%',
      padding: 5,
      display: 'flex',
      justifyContent: 'center',
    },
    textContainer: {
      border: '1px solid black',
      height: '30px',
      paddingLeft: 5,
      paddingTop: 2,
      paddingBottom: 2,
    },
    titleRowText: {
      fontWeight: 'bold',
      margin: 'auto',
    },
  });

const checkboxEmpty = (
  <Svg style={{ width: 20, height: 20 }} viewBox="0 0 20 20">
    <Path
      fill="#000"
      d="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z"
    ></Path>
  </Svg>
);

const checkboxTicked = (
  <Svg style={{ width: 20, height: 20 }} viewBox="0 0 20 20">
    <Path
      fill="#000"
      d="M18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 L18.25,3 Z M18.25,4.5 L5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 Z M10,14.4393398 L16.4696699,7.96966991 C16.7625631,7.6767767 17.2374369,7.6767767 17.5303301,7.96966991 C17.7965966,8.23593648 17.8208027,8.65260016 17.6029482,8.94621165 L17.5303301,9.03033009 L10.5303301,16.0303301 C10.2640635,16.2965966 9.84739984,16.3208027 9.55378835,16.1029482 L9.46966991,16.0303301 L6.46966991,13.0303301 C6.1767767,12.7374369 6.1767767,12.2625631 6.46966991,11.9696699 C6.73593648,11.7034034 7.15260016,11.6791973 7.44621165,11.8970518 L7.53033009,11.9696699 L10,14.4393398 L16.4696699,7.96966991 L10,14.4393398 Z"
    ></Path>
  </Svg>
);

const TableRows = ({ sectionColumns, columns, values, sectionUids }) => {
  return (
    <>
      {sectionUids.map((optionUid, index) => {
        const foundOption = values[optionUid];
        const {
          rowTitle,
          id: rowId,
          titleRow,
          textColour,
          backgroundColour,
        } = foundOption;

        return (
          <View key={optionUid} style={styles({ index, titleRow }).tableRow}>
            {!titleRow && (
              <View style={styles({}).rowTitleContainer}>
                <Text style={styles({}).rowTitleText}>{rowTitle}</Text>
              </View>
            )}
            {columns.map((column) => {
              const { id: columnId, label, inputOptions } = column;
              const uid = `${rowId}-${columnId}`;
              if (!!inputOptions && !titleRow) {
                const inputOption =
                  foundOption[columnId][`${columnId}ActiveInputOption`] ||
                  inputOptions[0].title;
                const value = foundOption[columnId].value;
                if (inputOption === 'Checkbox') {
                  return (
                    <View key={uid} style={styles({}).tableCell}>
                      {value && checkboxTicked}
                      {!value && checkboxEmpty}
                    </View>
                  );
                } else if (inputOption === 'Textbox') {
                  return (
                    <View key={uid} style={styles({}).textboxContainer}>
                      <View style={styles({}).textContainer}>
                        <Text>{value}</Text>
                      </View>
                    </View>
                  );
                }
              }
            })}
          </View>
        );
      })}
    </>
  );
};

export default TableRows;
