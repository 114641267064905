import React from 'react';

import { Box, Grid, Switch, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../common/button/FanGoIconButton';
import CustomFanGoDialog from '../../common/dialog/CustomFanGoDialog';
import CustomDialogContent from './dialogContent/CustomDialogContent';
import { getTableSections } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'pink',
    },
    customisationOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    addRowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    downloadAndReviewContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })
);

const handleShowDialog = ({ type, setDialogContentType, setShowDialog }) => {
  setDialogContentType(type);
  setShowDialog(true);
};

const PlanInteractionOptions = ({
  quickRowDeletionEnabled,
  setQuickRowDeletionEnabled,
  setEventPlanningOptionsOrder,
  setEventPlanningOptions,
  initialEventOptionsArr,
  initialEventOptionsObj,
  setSections,
  setPlanReset,
  resetForm,
  setShowDialog,
  setDialogContentType,
  getEventPlanPDF,
  values,
  sections,
  showDialog,
  dialogContentType,
  logo,
}) => {
  const classes = useStyles();

  return (
    <>
      {showDialog && (
        <CustomFanGoDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <CustomDialogContent
            type={dialogContentType}
            setShowDialog={setShowDialog}
            setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
            setEventPlanningOptions={setEventPlanningOptions}
            initialEventOptionsArr={initialEventOptionsArr}
            initialEventOptionsObj={initialEventOptionsObj}
            sections={sections}
            setSections={setSections}
            addSection={true}
            setPlanReset={setPlanReset}
            resetForm={resetForm}
            logo={logo}
          />
        </CustomFanGoDialog>
      )}
      <Grid container>
        <Grid item xs={6} sx={{ rowGap: 2 }}>
          <Typography variant="h4" color="black">
            Table Interaction Options
          </Typography>
          <Grid container className={classes.customisationOption}></Grid>
          <Grid container className={classes.customisationOption}>
            <Grid item xs={6}>
              <Typography color="black">Enable Quick Row Deletion</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                checked={quickRowDeletionEnabled}
                onChange={() =>
                  setQuickRowDeletionEnabled(!quickRowDeletionEnabled)
                }
              />
            </Grid>
          </Grid>
          <Grid container className={classes.customisationOption}>
            <Grid item xs={6}>
              <Typography color="black">
                Reset Table Back to Defaults
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ pl: 1 }}>
              <FanGoIconButton
                type="reset"
                onClick={() =>
                  handleShowDialog({
                    type: 'resetPlan',
                    setDialogContentType,
                    setShowDialog,
                  })
                }
                size="medium"
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mb: 2 }} className={classes.downloadAndReviewContainer}>
            <Typography variant="h4" color="black" sx={{ pr: 1 }}>
              Preview Your Plan as a PDF
            </Typography>
            <FanGoIconButton
              type="preview"
              onClick={() =>
                handleShowDialog({
                  type: 'preview',
                  setDialogContentType,
                  setShowDialog,
                })
              }
              size="large"
              disabled={false}
            />
          </Box>
          <div className={classes.downloadAndReviewContainer}>
            <Typography variant="h4" color="black" sx={{ pr: 1 }}>
              Download Your Plan
            </Typography>
            <FanGoIconButton
              type="download"
              onClick={() => getEventPlanPDF({ values, sections, logo })}
              size="large"
              disabled={false}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanInteractionOptions;
