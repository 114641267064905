import React from 'react';

import { TableRow, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({ isDragging, titleRow }) => {
        if (isDragging) {
          return 'rgb(200, 200, 200)';
        }
        if (titleRow) {
          return 'rgb(45,155,131, 0.3)';
        }
        return 'inherit';
      },

      '&:nth-of-type(odd)': {
        backgroundColor: ({ isDragging, titleRow }) => {
          if (isDragging) {
            // return theme.palette.primary.main;
            return 'rgb(200, 200, 200)';
          }
          if (titleRow) {
            return 'rgb(45,155,131, 0.3)';
          }

          return 'rgb(245, 245, 245)';
        },
      },
    },
  })
);

export const DraggableTableRow: React.FC<{
  provided;
  isDragging: boolean;
  children;
  titleRow: boolean;
}> = ({ children, provided, isDragging, titleRow }) => {
  const classes = useStyles({ isDragging, titleRow });
  return (
    <TableRow
      className={classes.root}
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      {children}
    </TableRow>
  );
};
