import React from 'react';

import AddRowDialogContent from './AddRowDialogContent';
import DeleteRowDialogContent from './DeleteRowDialogContent';
import DeleteSectionDialogContent from './DeleteSectionDialogContent';
import EditColumnsDialogContent from './EditColumnsDialogContent';
import EditRowDialogContent from './EditRowDialogContent';
import EditTitleRowDialogContent from './EditTitleRowDialogContent';
import PdfPreviewDialongContent from './PdfPreviewDialongContent';
import ResetPlanDialogContent from './ResetPlanDialogContent';

const CustomDialogContent = ({
  type,
  columns = [],
  rowUid = '',
  setShowDialog,
  eventPlanningOptionsOrder = [],
  setEventPlanningOptionsOrder = [],
  eventPlanningOptions = {},
  setEventPlanningOptions = null,
  section = [],
  addRowToSection = false,
  sections = [],
  setSections = null,
  addSection = false,
  initialEventOptionsArr = [],
  initialEventOptionsObj = {},
  setPlanReset = null,
  resetForm = null,
  logo = null,
}) => {
  switch (type) {
    case 'editTitleRow':
      return (
        <EditTitleRowDialogContent
          rowUid={rowUid}
          setShowDialog={setShowDialog}
          sections={sections}
        />
      );
    case 'edit':
      return (
        <EditRowDialogContent
          columns={columns}
          rowUid={rowUid}
          setShowDialog={setShowDialog}
        />
      );

    case 'delete':
      return (
        <DeleteRowDialogContent
          rowUid={rowUid}
          setShowDialog={setShowDialog}
          eventPlanningOptionsOrder={eventPlanningOptionsOrder}
          setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
          eventPlanningOptions={eventPlanningOptions}
          setEventPlanningOptions={setEventPlanningOptions}
          section={section}
          sections={sections}
          setSections={setSections}
        />
      );
    case 'deleteSection':
      return (
        <DeleteSectionDialogContent
          section={section}
          rowUid={rowUid}
          setShowDialog={setShowDialog}
          eventPlanningOptionsOrder={eventPlanningOptionsOrder}
          setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
          eventPlanningOptions={eventPlanningOptions}
          setEventPlanningOptions={setEventPlanningOptions}
          sections={sections}
          setSections={setSections}
        />
      );

    case 'add':
      return (
        <AddRowDialogContent
          columns={columns}
          eventPlanningOptionsOrder={eventPlanningOptionsOrder}
          setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
          setEventPlanningOptions={setEventPlanningOptions}
          eventPlanningOptions={eventPlanningOptions}
          setShowEditDialog={setShowDialog}
          addRowToSection={addRowToSection}
          section={section}
          sections={sections}
          setSections={setSections}
          addSection={addSection}
        />
      );
    case 'preview':
      return <PdfPreviewDialongContent sections={sections} logo={logo} />;
    case 'editColumns':
      return (
        <EditColumnsDialogContent
          setShowDialog={setShowDialog}
          section={section}
          sections={sections}
        />
      );
    case 'resetPlan':
      return (
        <ResetPlanDialogContent
          setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
          setEventPlanningOptions={setEventPlanningOptions}
          initialEventOptionsArr={initialEventOptionsArr}
          initialEventOptionsObj={initialEventOptionsObj}
          setSections={setSections}
          setPlanReset={setPlanReset}
          resetForm={resetForm}
          setShowDialog={setShowDialog}
        />
      );
  }

  return null;
};

export default CustomDialogContent;
