import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = (props?: any) =>
  StyleSheet.create({
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#141855',
    },
    doneTableCell: {
      width: '10%',
      paddingLeft: 10,
    },
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30%',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      color: props.textColour,
      backgroundColor: props.backgroundColour,
      height: '100%',
    },
    emptyCell: {
      padding: 1,
      width: '40%',
      height: '100%',
      backgroundColor: props.backgroundColour,
      paddingBottom: 5,
    },
    titleRowContainer: {
      backgroundColor: props.backgroundColour,
      color: props.textColour,
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
    },
  });

const TableHeaders = ({ columns, titleRow }) => {
  const { rowTitle, backgroundColour, textColour } = titleRow;
  return (
    <>
      <View>
        {titleRow && (
          <View
            style={styles({ textColour, backgroundColour }).titleRowContainer}
          >
            <Text style={{ fontFamily: 'Open Sans' }}>{rowTitle}</Text>
          </View>
        )}
      </View>
      <View style={styles({}).headerRow}>
        {columns.map((column) => {
          const { id, label, textColour, backgroundColour } = column;
          let columnTitle = label;
          if (label === 'Person Responsible') {
            const splitLabel = label.split(' ');
            columnTitle = `${splitLabel[0]}\n${splitLabel[1]}`;
          }
          if (label) {
            return (
              <View
                key={id}
                style={styles({ textColour, backgroundColour }).tableCell}
              >
                <Text>{columnTitle}</Text>
              </View>
            );
          } else if (id === 'emptyCellOne') {
            return (
              <View key={id} style={styles({ backgroundColour }).emptyCell} />
            );
          }
          return null;
        })}
      </View>
    </>
  );
};

export default TableHeaders;
