import React from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

const DialogTitle = ({ titleText }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.dialogTitle}
      sx={{ mb: 2 }}
      variant="h3"
      textAlign="center"
    >
      {titleText}
    </Typography>
  );
};

export default DialogTitle;
