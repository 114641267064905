import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../../common/button/FanGoIconButton';
import SubmitButton from '../../../common/button/SubmitButton';
import ColourPicker from '../../../common/colourPicker/ColourPicker';
import DialogContentContainer from './DialogContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 3,
    },
    container: {
      padding: theme.spacing(3),
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    colourPickerContainer: {
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
      borderRadius: 10,
    },
    title: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    titleAndColourInputContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    colorPicker: {
      flex: 1,
    },

    // preview styles
    accordionTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: theme.spacing(1.5),
    },
    dragIconContainer: {
      display: 'flex',
    },
    accordionSummaryContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    editAndDeleteContainer: {
      display: 'flex',
      columnGap: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
  })
);

const handleSubmitEdit = ({
  row,
  rowUid,
  handleChange,
  selectedTextColour,
  selectedBackgroundColour,
  setShowDialog,
  rowTitle,
  applyTextColourToAllSections,
  applyBackgroundColourToAllSections,
  sections,
  values,
}) => {
  const updateRow = cloneDeep(row);
  updateRow.rowTitle = rowTitle;
  updateRow.textColour = selectedTextColour;
  updateRow.backgroundColour = selectedBackgroundColour;

  if (applyTextColourToAllSections || applyBackgroundColourToAllSections) {
    sections.forEach((section) => {
      const { id } = section;
      const allSectionsUpdateRow = cloneDeep(values[id]);

      if (applyTextColourToAllSections) {
        allSectionsUpdateRow.textColour = selectedTextColour;
      }
      if (applyBackgroundColourToAllSections) {
        allSectionsUpdateRow.backgroundColour = selectedBackgroundColour;
      }
      handleChange({ target: { id, value: allSectionsUpdateRow } });
    });
  } else {
    handleChange({ target: { id: rowUid, value: updateRow } });
  }

  setShowDialog(false);
};

const EditTitleRowDialogContent = ({ rowUid, setShowDialog, sections }) => {
  const classes = useStyles();
  const { handleChange, values } = useFormikContext();
  const { rowTitle, backgroundColour, textColour } = values[rowUid];
  const [textValue, setTextValue] = useState(rowTitle);
  const [selectedTextColour, setSelectedTextColour] = useState(textColour);
  const [selectedBackgroundColour, setSelectedBackgroundColour] =
    useState(backgroundColour);
  const [applyTextColourToAllSections, setApplyTextColourToAllSections] =
    useState(false);
  const [
    applyBackgroundColourToAllSections,
    setApplyBackgroundColourToAllSections,
  ] = useState(false);

  const editOptions = [
    {
      selectedColour: selectedTextColour,
      setSelectedColour: setSelectedTextColour,
      textValue: textValue,
      editTextTitle: 'Edit Text Colour',
    },
    {
      selectedColour: selectedBackgroundColour,
      setSelectedColour: setSelectedBackgroundColour,
      textValue: '',
      editTextTitle: 'Edit Section Title Background Colour',
    },
  ];

  return (
    <DialogContentContainer dialogTitle={rowTitle}>
      <div className={classes.contentContainer}>
        <Typography sx={{ minWidth: '25%' }}>Edit Row Title:</Typography>
        <TextField
          variant="outlined"
          size="small"
          id={'value'}
          name={'value'}
          placeholder={values[rowUid].rowTitle}
          value={textValue}
          label={values[rowUid].rowTitle}
          sx={{ ml: 2, width: '100%' }}
          onChange={(e): void => {
            const newValue = e.target.value;
            setTextValue(newValue);
          }}
        />
      </div>

      {editOptions.map((option) => {
        const { selectedColour, setSelectedColour, textValue, editTextTitle } =
          option;
        return (
          <ColourPicker
            key={editTextTitle}
            selectedColour={selectedColour}
            setSelectedColour={setSelectedColour}
            text={textValue}
            editTextTitle={editTextTitle}
          />
        );
      })}

      <Box sx={{ maxWidth: '600px' }}>
        <FormControlLabel
          control={
            <Checkbox
              value={applyTextColourToAllSections}
              onChange={() =>
                setApplyTextColourToAllSections(!applyTextColourToAllSections)
              }
            />
          }
          label={'Apply Text colour changes to all sections'}
        />
        <FormControlLabel
          control={
            <Checkbox
              value={applyBackgroundColourToAllSections}
              onChange={() =>
                setApplyBackgroundColourToAllSections(
                  !applyBackgroundColourToAllSections
                )
              }
            />
          }
          label={'Apply Background colour changes to all sections'}
        />
      </Box>
      <Typography sx={{ fontWeight: 'bold' }}>Preview</Typography>
      <Box
        sx={{
          width: '100%',
          backgroundColor: selectedBackgroundColour,
          p: 2,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <div className={classes.accordionSummaryContentContainer}>
          <div className={classes.accordionTitleContainer}>
            <div
              className={classes.dragIconContainer}
              style={{ color: selectedTextColour }}
            >
              <DragIndicatorIcon color={selectedTextColour} />
            </div>
            <Typography variant="h4" sx={{ mr: 1, color: selectedTextColour }}>
              {textValue}
            </Typography>
          </div>
          <div className={classes.editAndDeleteContainer}>
            <FanGoIconButton
              type={'edit'}
              onClick={() => {}}
              size={'medium'}
              disabled={false}
            />
            <FanGoIconButton
              type={'delete'}
              onClick={() => {}}
              size={'medium'}
              disabled={false}
            />
          </div>
        </div>
      </Box>

      <SubmitButton
        onClick={() =>
          handleSubmitEdit({
            row: values[rowUid],
            rowUid,
            rowTitle,
            selectedTextColour,
            selectedBackgroundColour,
            handleChange,
            setShowDialog,
            applyTextColourToAllSections,
            applyBackgroundColourToAllSections,
            sections,
            values,
          })
        }
        disabled={
          selectedTextColour.length <= 3 || selectedBackgroundColour.length <= 3
        }
      />
    </DialogContentContainer>
  );
};

export default EditTitleRowDialogContent;
