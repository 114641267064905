import { useFormikContext } from 'formik';
import React from 'react';

import { Typography } from '@mui/material';

import SubmitButton from '../../../common/button/SubmitButton';
import { handleDeleteSection } from '../utils';
import DialogContentContainer from './DialogContentContainer';

const DeleteSectionDialogContent = ({
  rowUid,
  setShowDialog,
  eventPlanningOptionsOrder,
  setEventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptions,
  section,
  sections,
  setSections,
}) => {
  const { values } = useFormikContext();
  const rowTitle = values[rowUid].rowTitle;

  return (
    <DialogContentContainer dialogTitle="Section Deletion Confirmation">
      <Typography>
        Deleting the section titled: {rowTitle}, will delete all sub-rows, are
        you sure you want to continue?
      </Typography>
      <SubmitButton
        onClick={() =>
          handleDeleteSection({
            section,
            setShowDialog,
            eventPlanningOptionsOrder,
            setEventPlanningOptionsOrder,
            eventPlanningOptions,
            setEventPlanningOptions,
            sections,
            setSections,
          })
        }
      />
    </DialogContentContainer>
  );
};

export default DeleteSectionDialogContent;
