import React from 'react';

import { Document, Page, View } from '@react-pdf/renderer';

import PageFooter from './PageFooter';
import TableHeaders from './TableHeaders';
import TableRows from './TableRows';

const Table = ({ sections, values, logo }) => {
  return (
    <Document>
      {sections.map((section, index) => {
        const { id } = section;
        const thisSection = values[id];
        if (thisSection) {
          const { sectionColumns } = values[id];
          const titleRow = values[id];
          return (
            <Page size="A4" key={section.id}>
              <View fixed>
                <TableHeaders columns={sectionColumns} titleRow={titleRow} />
              </View>
              <TableRows
                sectionColumns={sectionColumns}
                values={values}
                columns={sectionColumns}
                sectionUids={section.sectionUids}
              />
              <PageFooter
                numberOfPages={sections.length}
                currentPage={index}
                logo={logo}
              />
            </Page>
          );
        }
        return null;
      })}
    </Document>
  );
};

export default Table;
