import React, { Dispatch, ReactElement } from 'react';

import { Typography } from '@mui/material';

import SubmitButton from '../../../common/button/SubmitButton';
import { getTableSections } from '../utils';
import DialogContentContainer from './DialogContentContainer';

type resetPlanTypes = {
  setEventPlanningOptionsOrder: Dispatch<{}[]>;
  setEventPlanningOptions: Dispatch<{}>;
  initialEventOptionsArr: {}[];
  initialEventOptionsObj: {};
  setSections: Dispatch<{}[]>;
  setPlanReset: Dispatch<boolean>;
  resetForm;
};

const ResetPlanDialogContent = ({
  setEventPlanningOptionsOrder,
  setEventPlanningOptions,
  initialEventOptionsArr,
  initialEventOptionsObj,
  setSections,
  setPlanReset,
  resetForm,
  setShowDialog,
}) => {
  const handleTableReset = () => {
    setEventPlanningOptionsOrder(initialEventOptionsArr);
    setEventPlanningOptions(initialEventOptionsObj);

    const tableSections = getTableSections({
      optionsArr: initialEventOptionsArr,
      optionsObj: initialEventOptionsObj,
    });
    setSections(tableSections);
    setPlanReset(true);

    resetForm();
    setShowDialog(false);
  };

  return (
    <DialogContentContainer dialogTitle={'Reset Current Event Plan'}>
      <Typography textAlign="center">
        Are you sure you want to reset your current event plan?
      </Typography>
      <Typography textAlign="center" sx={{ mb: 2 }}>
        Doing so will result in all changes been permanently lost.
      </Typography>
      <SubmitButton onClick={handleTableReset} />
    </DialogContentContainer>
  );
};

export default ResetPlanDialogContent;
