import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import { Grid, MenuItem, TextField, Theme, Typography } from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import SubmitButton from '../../../common/button/SubmitButton';
import DividerLine from '../../../common/dividers/DividerLine';
import { rowOptions } from '../utils';
import DialogContentContainer from './DialogContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 3,
    },
    container: {
      padding: theme.spacing(2),
    },
    contentContainer: {
      display: 'flex',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      alignItems: 'center',
    },
    editRowTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(2.5),
    },
  })
);

const getRowCustomisationOptions = ({ values, rowUid }) => {
  const options = rowOptions.map((option, index) => {
    const { id } = option;
    const value = values[rowUid][id];
    option.value = value;
    return option;
  });
  return options;
};

const EditRowDialogContent = ({ columns, rowUid, setShowDialog }) => {
  const { handleChange, values } = useFormikContext();
  const { rowTitle } = values[rowUid];
  const [clonedRow, setClonedRow] = useState(cloneDeep(values[rowUid]));
  const [rowCustomisationOptions, setRowCustomisationOptions] = useState(
    getRowCustomisationOptions({ values, rowUid })
  );
  const [titleText, setTitleText] = useState(rowTitle);
  const classes = useStyles();

  const handleEditSubmit = () => {
    const updateRow = { ...clonedRow };

    updateRow.rowTitle = titleText;
    rowCustomisationOptions.forEach((option) => {
      const { id, value } = option;
      updateRow[id] = value;
    });
    handleChange({
      target: { id: rowUid, value: updateRow },
    });
    setShowDialog(false);
  };

  return (
    <DialogContentContainer dialogTitle={rowTitle}>
      <div className={classes.editRowTitleContainer}>
        <Typography>Row Title:&nbsp;</Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          placeholder={values[rowUid].rowTitle}
          label={values[rowUid].rowTitle}
          value={titleText}
          onChange={(e): void => {
            const newValue = e.target.value;
            setTitleText(newValue);
          }}
        />
      </div>
      <Typography variant="h5">Columns</Typography>
      {columns.map((column) => {
        const { label, id: columnId } = column;
        const inputOptions = clonedRow[columnId]?.[`${columnId}InputOptions`];
        if (!!inputOptions && inputOptions.length) {
          const id = `${rowUid}.${columnId}.${columnId}ActiveInputOption`;
          const value = clonedRow[columnId][`${columnId}ActiveInputOption`];
          return (
            <Grid container key={`${columnId}-container`}>
              <Grid item xs={12} sm={5} className={classes.contentContainer}>
                <Typography sx={{ pr: 2 }}>{label}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                sx={{ flexDirection: 'row' }}
                className={classes.contentContainer}
              >
                {inputOptions.length > 1 && (
                  <TextField
                    variant="outlined"
                    size="small"
                    id={id}
                    name={id}
                    select
                    value={value}
                    onChange={(e): void => {
                      const updatedRow = cloneDeep(clonedRow);
                      updatedRow[columnId][`${columnId}ActiveInputOption`] =
                        e.target.value;
                      setClonedRow(updatedRow);
                    }}
                    sx={{ minWidth: '135px' }}
                  >
                    {inputOptions.map((option) => {
                      const { title, id } = option;
                      return (
                        <MenuItem key={id} value={title}>
                          {title}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
                {inputOptions.length === 1 && (
                  <Typography>{inputOptions[0].title}</Typography>
                )}
              </Grid>
              <DividerLine />
            </Grid>
          );
        }
      })}
      <SubmitButton onClick={handleEditSubmit} />
    </DialogContentContainer>
  );
};

export default EditRowDialogContent;
