import { useFormikContext } from 'formik';
import React from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import SubmitButton from '../../../common/button/SubmitButton';
import { handleDeleteRow } from '../utils';
import DialogContentContainer from './DialogContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  })
);

const DeleteRowDialogContent = ({
  rowUid,
  setShowDialog,
  eventPlanningOptionsOrder,
  setEventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptions,
  section,
  sections,
  setSections,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const rowTitle = values[rowUid].rowTitle;

  return (
    <DialogContentContainer dialogTitle="Row Deletion Confirmation">
      <Typography>
        Are sure you want to delete the row titled: {rowTitle}
      </Typography>
      <SubmitButton
        onClick={() =>
          handleDeleteRow({
            rowUid,
            setShowDialog,
            eventPlanningOptionsOrder,
            setEventPlanningOptionsOrder,
            eventPlanningOptions,
            setEventPlanningOptions,
            sections,
            section,
            setSections,
          })
        }
      />
    </DialogContentContainer>
  );
};

export default DeleteRowDialogContent;
