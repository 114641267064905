import { useFormikContext } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useState } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import SubmitButton from '../../../common/button/SubmitButton';
import DividerLine from '../../../common/dividers/DividerLine';
import { getRowValues, rowOptions } from '../utils';
import DialogContentContainer from './DialogContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    editRowTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    contentContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    optionsContainer: {
      display: 'flex',
    },
    centeredContentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tableCell: {
      textAlign: 'center !important',
    },
    scrollableContainer: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    scrollableContent: {
      minWidth: '430px',
    },
  })
);

const getRowCreationInitialValue = ({
  rowCreationOptions,
  addRowToSection,
}) => {
  let initialValue = rowCreationOptions[0].title;
  if (addRowToSection) {
    initialValue = 'Create Editable Table Row';
  }
  return initialValue;
};

const AddRowDialogContent = ({
  columns,
  eventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptionsOrder,
  setEventPlanningOptions,
  setShowEditDialog,
  addRowToSection,
  section,
  sections = [],
  setSections,
  addSection,
}) => {
  const classes = useStyles();

  const [rowTitleText, setRowTitleText] = useState('');
  const [selectedInputOptions, setSelectedInputOptions] = useState({});
  const [submitWarning, setSubmitWarning] = useState('');
  const [submitCount, setSubmitCount] = useState(0);

  const rowCustomisationOptions = cloneDeep(rowOptions);
  const { setValues } = useFormikContext();

  const rowCreationOptions = [
    { title: 'Create Title Row', id: 'createTitleRow' },
    { title: 'Create Editable Table Row', id: 'createEditableTableRow' },
  ];
  const [rowCreationValue, setRowCreationValue] = useState(
    getRowCreationInitialValue({ addRowToSection, rowCreationOptions })
  );

  const submitWarningRowTitle = 'Please provide a Row Title';
  const isTitleRow = rowCreationValue === 'Create Title Row';

  const handleAddRowSubmit = () => {
    if (!rowTitleText) {
      setSubmitCount(submitCount + 1);
      setSubmitWarning(submitWarningRowTitle);
    } else {
      const row = { titleRow: isTitleRow };
      rowCustomisationOptions.forEach((option) => {
        const { id, value } = option;
        row[id] = value;
      });
      row['title'] = rowTitleText;

      const rows = [row];
      const { eventOptionsObj, eventOptionsArr } = getRowValues({
        columns,
        rows,
        selectedInputOptions,
      });

      const updateArr = [...eventPlanningOptionsOrder, ...eventOptionsArr];
      const updateObj = { ...eventPlanningOptions, ...eventOptionsObj };

      setValues((prevValues) => {
        return { ...prevValues, ...eventOptionsObj };
      });

      const sectionIndex = isEmpty(section)
        ? sections.length
        : sections.indexOf(section);
      const sectionsClone = cloneDeep(sections);

      if (isTitleRow) {
        const newRowUid = eventOptionsArr[0];
        let insertIndex = 0;
        if (sections.length) {
          insertIndex = sectionIndex + 1;
        }
        const newSection = {
          title: rowTitleText,
          sectionUids: [newRowUid],
          id: newRowUid,
        };
        sectionsClone.splice(insertIndex, 0, newSection);
      } else {
        const { sectionUids, id } = section;
        console.log(section);
        const clonedSection = cloneDeep(section);
        const updatedSectionUids = [...sectionUids, ...eventOptionsArr];
        clonedSection.sectionUids = [...updatedSectionUids];

        sectionsClone.splice(sectionIndex, 1, clonedSection);

        const newRowPositionIndex = sectionUids.length;
        const indexOfSectionTitle = eventPlanningOptionsOrder.indexOf(id);
        const insertPosition = indexOfSectionTitle + newRowPositionIndex;
        eventPlanningOptionsOrder.splice(insertPosition, 0, ...eventOptionsArr);
      }

      setSections(sectionsClone);
      setEventPlanningOptions(updateObj);
      setEventPlanningOptionsOrder(updateArr);

      setShowEditDialog(false);
    }
  };

  const rowIsDraggable = rowCustomisationOptions.some(
    (e) => e.id === 'draggable' && e.value
  );

  const filteredColumns = columns.filter((column) => {
    const { label, inputOptions, id } = column;
    if (label && inputOptions) {
      let selectedOption = inputOptions[0].title;
      const foundSelectedOption = selectedInputOptions[id];
      column.selectedOption = foundSelectedOption?.value || selectedOption;

      return column;
    }
  });

  let dialogTitle = '';
  if (!addRowToSection && isTitleRow) {
    dialogTitle = 'Add New Section';
  } else if (addRowToSection && isTitleRow) {
    dialogTitle = 'Insert New Row Below Current Section';
  } else if (addRowToSection) {
    dialogTitle = 'Add New Row to Current Section';
  }

  return (
    <DialogContentContainer dialogTitle={dialogTitle}>
      {!addSection && (
        <div className={classes.centeredContentContainer}>
          <TextField
            select
            value={rowCreationValue}
            onChange={(e): void => {
              setRowCreationValue(e.target.value);
            }}
          >
            {rowCreationOptions.map((option) => {
              const { title, id } = option;
              return (
                <MenuItem key={id} value={title}>
                  {title}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      )}
      <div className={classes.editRowTitleContainer}>
        <Typography>Row Title:&nbsp;</Typography>
        <TextField
          sx={{ width: '100%' }}
          placeholder="Example Row Title"
          label="Example Row Title"
          value={rowTitleText}
          onChange={(e): void => {
            const value = e.target.value;
            if (!value && submitCount) {
              setSubmitWarning(submitWarningRowTitle);
            } else if (value && submitCount && submitWarning) {
              setSubmitWarning('');
            }
            setRowTitleText(e.target.value);
          }}
        />
      </div>

      {rowCreationValue === 'Create Editable Table Row' && (
        <>
          <Typography variant="h5">Input Options</Typography>
          {columns.map((column) => {
            const { label, inputOptions, id } = column;
            if (label && inputOptions) {
              const activeInputOptionTitle = inputOptions?.[0]?.title;
              return (
                <Grid
                  container
                  key={`${id}-container`}
                  className={classes.contentContainer}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className={classes.contentContainer}
                  >
                    <Typography sx={{ pr: 2 }}>
                      Option: {label}&nbsp;
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{ flexDirection: 'row' }}
                    className={classes.contentContainer}
                  >
                    <Typography>Input Option(s):&nbsp;</Typography>
                    {inputOptions.length > 1 && (
                      <TextField
                        select
                        value={
                          selectedInputOptions?.[id]?.value ||
                          activeInputOptionTitle
                        }
                        onChange={(e): void => {
                          const newValue = {
                            [id]: {
                              id: id,
                              value: e.target.value,
                            },
                          };
                          setSelectedInputOptions({
                            ...selectedInputOptions,
                            ...newValue,
                          });
                        }}
                      >
                        {inputOptions.map((inputOption) => {
                          const { title, id } = inputOption;
                          return (
                            <MenuItem key={id} value={title}>
                              {title}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                    {inputOptions.length === 1 && (
                      <Typography>{inputOptions[0].title}</Typography>
                    )}
                  </Grid>
                  <DividerLine />
                </Grid>
              );
            }
            return null;
          })}

          <Box sx={{ mt: 3 }}>
            <Typography variant="h5">Row Preview</Typography>
            <Box className={classes.scrollableContainer}>
              <Table className={classes.scrollableContent}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {rowIsDraggable && <DragIndicatorIcon />}
                    </TableCell>
                    <TableCell>
                      <Typography>{rowTitleText}</Typography>
                    </TableCell>
                    {filteredColumns.map((column) => {
                      const { selectedOption, id, label } = column;
                      return (
                        <TableCell
                          key={`${id}-rowPreview`}
                          className={classes.tableCell}
                        >
                          {selectedOption === 'Checkbox' && (
                            <Checkbox disabled />
                          )}
                          {selectedOption === 'Textbox' && (
                            <TextField placeholder={label} disabled />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </>
      )}
      {submitWarning && (
        <Typography sx={{ mt: 2, mb: 2 }} textAlign="center" color="red">
          {submitWarning}
        </Typography>
      )}
      <SubmitButton onClick={handleAddRowSubmit} />
    </DialogContentContainer>
  );
};

export default AddRowDialogContent;
