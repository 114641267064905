import React from 'react';

import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 3,
    },
  })
);

const SubmitButton = ({ onClick, disabled = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonContainer}>
      <Button
        sx={{ pl: 5, pr: 5, pt: 1, pb: 1, mt: 2 }}
        variant="contained"
        onClick={onClick}
        disabled={disabled}
      >
        Submit
      </Button>
    </div>
  );
};

export default SubmitButton;
