import { useFormikContext } from 'formik';
import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PDFViewer } from '@react-pdf/renderer';

import Table from '../reactPdfComps/Table';
import DialogContentContainer from './DialogContentContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredContentContainer: {
      height: '100%',
      minHeight: '50vh',
      overflow: 'hidden',
    },
  })
);

const PdfPreviewDialongContent = ({ sections, logo }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  return (
    <DialogContentContainer dialogTitle="PDF Preview of Your Event Plan">
      <div className={classes.centeredContentContainer}>
        <PDFViewer style={{ height: '50vh', width: '100%' }}>
          <Table values={values} sections={sections} logo={logo} />
        </PDFViewer>
      </div>
    </DialogContentContainer>
  );
};

export default PdfPreviewDialongContent;
