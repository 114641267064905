import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import useOnScreen from '../../../hooks/useOnScreen';
import { FanGoTextProcessor } from '../../common/portableText';
import {
  checkAndRenderList,
  formatLists,
  formatSubTitle,
  getEndOfTextLists,
} from './planYourEventContentUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const listContainerStyles = {
  marginTop: '8px',
  marginBottom: '8px',
  display: 'flex',
  rowGap: '8px',
};

const PlanYourEventContentSection = ({ section, id }) => {
  const uid = uuidv4();
  const { title, subTitle, _rawBody: paragraphs, lists, image } = section;
  let imageData = null;
  if (image) {
    const { asset } = image;
    imageData = asset?.gatsbyImageData;
  }

  const formattedParagraphs = [];
  if (paragraphs && paragraphs.length) {
    paragraphs.forEach((paragraph) => {
      formattedParagraphs.push(<FanGoTextProcessor text={paragraph} />);
    });
  }

  const formattedLists = formatLists({ lists });
  const formattedEndLists = getEndOfTextLists({
    formattedLists,
    textBlocksLength: formattedParagraphs.length,
  });

  return (
    <>
      <div
        key={uid}
        style={{
          scrollMargin: '75px',
          paddingLeft: '8px',
          paddingRight: '8px',
          marginBottom: '24px',
        }}
        id={id}
      >
        {subTitle && (
          <Typography variant={'h4'} color="black">
            {subTitle}
          </Typography>
        )}
        {!subTitle && (
          <Typography variant={'h3'} color={'#2d9b83'}>
            {title}
          </Typography>
        )}
        {formattedParagraphs.map((paragraph, index) => {
          const uid = uuidv4();
          return (
            <div key={uid}>
              {paragraph}
              <div style={{ ...listContainerStyles }}>
                {checkAndRenderList({ index, formattedLists })}
              </div>
            </div>
          );
        })}
        {!!formattedEndLists && (
          <div style={{ ...listContainerStyles }}>
            {formattedEndLists.map((list) => {
              const { itemsList } = list;
              const uid = uuidv4();
              return (
                <div key={uid}>{itemsList.map((listItem) => listItem)}</div>
              );
            })}
          </div>
        )}
      </div>
      {imageData && (
        <div style={{ marginTop: '24px', marginBottom: '24px' }}>
          <GatsbyImage
            image={imageData}
            alt={''}
            objectFit="cover"
            style={{
              maxHeight: '350px',
              borderRadius: '8px 8px 8px 8px',
            }}
          />
        </div>
      )}
    </>
  );
};

export default PlanYourEventContentSection;
