import React, { useContext, useEffect, useRef, useState } from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { DataContext } from '../../../providers/DataProvider';
import { formatSubTitle } from './planYourEventContentUtils';
import { handleScrollToTitle } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'sticky',
      top: '20%',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      borderRadius: 20,
      boxShadow: '0 3px 10px rgb(140, 152, 164, 0.3)',
      paddingBottom: theme.spacing(2),
    },
    contentsTitleContainer: {
      backgroundColor: 'rgb(230, 230, 230)',
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.3),
      borderBottom: '1px solid rgb(210, 210, 210)',
      marginBottom: theme.spacing(1),
    },
    contentContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      overflowY: 'auto',
      height: '65vh',
      maxHeight: '700px',
      scrollbarWidth: 'thin',
    },
    contentTitle: {
      fontWeight: 800,
      paddingBottom: theme.spacing(1),
      cursor: 'pointer',
    },
  })
);

const observerCallback = ({
  entries,
  currentScrollSection,
  contentsContainerRef,
}) => {
  // const intersectingEntries = entries.filter((entry) => entry.isIntersecting);
  // const foundEntries = intersectingEntries.map((entry) => {
  //   return entry.target.id.substring(9, entry.length);
  // });
  // if (!foundEntries.includes(currentScrollSection)) {
  //   const id = `contents-${currentScrollSection}`;
  //   const foundElement = document.getElementById(id);
  //   if (foundElement) {
  //     foundElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  //   }
  // }
  // if (intersectingEntry.length) {
  //   const foundEntry = intersectingEntry[0]?.target?.id;
  //   const formattedId = foundEntry.substring(9, foundEntry.length);
  //   console.log(formattedId);
  //   console.log(currentScrollSection);
  // }
  // let foundEntry = false;
  // if (!foundEntry) {
  //   console.log(`${currentScrollSection} is not currently visible`);
  // }
};

const ContentsContainer = ({ paragraphs }) => {
  const classes = useStyles();
  const { currentScrollSection = '' } = useContext(DataContext);
  const [contentTitles, setContentTitles] = useState([]);
  const contentsContainerRef = useRef();

  if (contentTitles.length === 0) {
    const arr = ['Event Planning Checklist'];
    paragraphs.forEach((paragraph) => {
      const { title, subTitle } = paragraph;
      if (title) {
        arr.push(title);
      } else if (subTitle) {
        let formattedSubTitle = formatSubTitle({ subTitle });
        arr.push(formattedSubTitle);
      }
    });
    setContentTitles(arr);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) =>
        observerCallback({
          entries,
          currentScrollSection,
          contentsContainerRef,
        }),
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    contentTitles.forEach((title) => {
      const foundElement = document.getElementById(`contents-${title}`);
      if (foundElement) {
        observer.observe(foundElement);
      }
    });
    return () => {
      observer.disconnect();
    };
  }, [currentScrollSection]);

  return (
    <div className={classes.container}>
      <div className={classes.contentsTitleContainer}>
        <Typography>Contents</Typography>
      </div>
      <div className={classes.contentContainer} ref={contentsContainerRef}>
        {contentTitles.map((title) => {
          return (
            <Typography
              key={title}
              id={`contents-${title}`}
              className={classes.contentTitle}
              variant="subtitle2"
              onClick={() => handleScrollToTitle({ title })}
              sx={{
                '&:hover': { color: 'rgb(0, 0, 0)' },
                color:
                  title === currentScrollSection
                    ? 'rgb(0, 0, 0)'
                    : 'rgb(100, 100, 100)',
                scrollMargin: '10px',
              }}
            >
              {title}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default ContentsContainer;
