import React, { useEffect, useState } from 'react';

import { Accordion } from '@mui/material';

const CustomAccordion = ({
  id,
  index,
  provided,
  planReset,
  setPlanReset,
  sections,
  children,
}) => {
  const [expanded, setExpanded] = useState(index === 0);

  useEffect(() => {
    if (index === 0 && !expanded) {
      setExpanded(true);
    }

    if (index > 0 && expanded) {
      setExpanded(false);
    }

    if (index === sections.length - 1) {
      setPlanReset(false);
    }
  }, [planReset === true]);

  return (
    <Accordion
      {...provided.draggableProps}
      ref={provided.innerRef}
      key={id}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{ mb: 1 }}
      TransitionProps={{ unmountOnExit: true }}
    >
      {children}
    </Accordion>
  );
};

export default CustomAccordion;
