import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../common/button/FanGoIconButton';
import CustomFanGoDialog from '../../common/dialog/CustomFanGoDialog';
import CustomAccordion from '../CommonComps/accordion/CustomAccordion';
import AccordionSummaryContentAndButtons from './AccordionSummaryContentAndButtons';
import CustomDialogContent from './dialogContent/CustomDialogContent';
import EventPlanningTableHead from './EventPlanningTableHead';
import EventPlanningTableRow from './EventPlanningTableRow';
import { handleDeleteSection, processDragEnd } from './utils';

const accordionSummaryStyles = {
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  paddingLeft: 2,
  paddingRight: 2,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      width: '100%',
      position: 'relative',
    },

    headerCell: {
      color: 'white',
      backgroundColor: 'red',
    },
    addRowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    accordionTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: theme.spacing(1.5),
    },
    dragIconContainer: {
      display: 'flex',
      color: 'white',
    },
    accordionSummaryContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    expandMoreIcon: {
      color: 'white',
      fontSize: '35px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const EventPlanningTable = ({
  eventPlanningOptionsOrder,
  setEventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptions,
  columns,
  quickRowDeletionEnabled,
  sections,
  setSections,
  planReset,
  setPlanReset,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { values } = useFormikContext();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');
  const [rowUid, setRowUid] = useState('');
  const [currentSection, setCurrentSection] = useState([]);
  const [addRowToSection, setAddRowToSection] = useState(false);

  const onDragEnd = (result) => {
    const newOptionsOrder = processDragEnd({
      result,
      sections,
      values,
    });

    if (!newOptionsOrder) {
      return;
    }

    setSections(newOptionsOrder);
  };

  const handleShowDialog = ({ id = '', type }) => {
    setRowUid(id);
    setDialogContentType(type);
    setShowDialog(true);
  };

  const handleDeleteSectionClick = ({ id, type, section }) => {
    setCurrentSection(section);

    if (quickRowDeletionEnabled) {
      handleDeleteSection({
        section,
        setShowDialog,
        eventPlanningOptionsOrder,
        setEventPlanningOptionsOrder,
        eventPlanningOptions,
        setEventPlanningOptions,
        sections,
        setSections,
      });
    } else {
      handleShowDialog({ id, type });
    }
  };

  const handleAddRow = ({ section }) => {
    setCurrentSection(section);
    setAddRowToSection(true);
    handleShowDialog({ type: 'add' });
  };

  const handleEditColumnTitles = ({ section }) => {
    setCurrentSection(section);
    handleShowDialog({ type: 'editColumns' });
  };

  return (
    <>
      {showDialog && (
        <CustomFanGoDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <CustomDialogContent
            section={currentSection}
            type={dialogContentType}
            columns={columns}
            rowUid={rowUid}
            setShowDialog={setShowDialog}
            eventPlanningOptionsOrder={eventPlanningOptionsOrder}
            setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
            eventPlanningOptions={eventPlanningOptions}
            setEventPlanningOptions={setEventPlanningOptions}
            addRowToSection={addRowToSection}
            sections={sections}
            setSections={setSections}
          />
        </CustomFanGoDialog>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="sectionsContainer"
          type="outerDroppableContainer"
        >
          {(containerProvided): JSX.Element => (
            <div
              {...containerProvided.droppableProps}
              ref={containerProvided.innerRef}
            >
              {sections.map((section, index) => {
                const { sectionUids, id } = section;
                const foundRow = values[id];
                if (foundRow) {
                  const {
                    rowTitle,
                    draggable,
                    sectionColumns,
                    textColour,
                    backgroundColour,
                  } = foundRow;
                  const title = rowTitle;

                  return (
                    <Draggable
                      draggableId={`drag_${id}`}
                      key={`drag_${id}`}
                      index={index}
                    >
                      {(provided): JSX.Element => (
                        <CustomAccordion
                          key={`${id}-accordion`}
                          id={id}
                          index={index}
                          provided={provided}
                          planReset={planReset}
                          setPlanReset={setPlanReset}
                          sections={sections}
                        >
                          <AccordionSummary
                            expandIcon={
                              <div className={classes.expandMoreIcon}>
                                <ExpandMoreIcon fontSize="inherit" />
                              </div>
                            }
                            sx={{
                              ...accordionSummaryStyles,
                              backgroundColor:
                                backgroundColour === '#'
                                  ? '#ffffff'
                                  : backgroundColour,
                            }}
                          >
                            <AccordionSummaryContentAndButtons
                              provided={provided}
                              title={title}
                              handleShowDialog={handleShowDialog}
                              id={id}
                              handleDeleteSectionClick={
                                handleDeleteSectionClick
                              }
                              section={section}
                              textColour={textColour}
                            />
                          </AccordionSummary>
                          <Droppable
                            droppableId={`drop_${id}`}
                            key={`drop_${id}`}
                            type="innerDroppableContainer"
                          >
                            {(droppableProvided): JSX.Element => (
                              <AccordionDetails
                                sx={{ padding: 0 }}
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                              >
                                <TableContainer
                                  className={classes.tableContainer}
                                >
                                  <Table>
                                    <EventPlanningTableHead
                                      sectionColumns={sectionColumns}
                                      setCurrentSection={setCurrentSection}
                                      handleShowDialog={handleShowDialog}
                                      section={section}
                                    />

                                    <TableBody>
                                      {sectionUids.map((optionUid, index) => {
                                        return (
                                          <EventPlanningTableRow
                                            columns={columns}
                                            key={optionUid}
                                            index={index}
                                            rowUid={optionUid}
                                            quickRowDeletionEnabled={
                                              quickRowDeletionEnabled
                                            }
                                            eventPlanningOptionsOrder={
                                              eventPlanningOptionsOrder
                                            }
                                            setEventPlanningOptionsOrder={
                                              setEventPlanningOptionsOrder
                                            }
                                            eventPlanningOptions={
                                              eventPlanningOptions
                                            }
                                            setEventPlanningOptions={
                                              setEventPlanningOptions
                                            }
                                            section={section}
                                            sections={sections}
                                            setSections={setSections}
                                          />
                                        );
                                      })}

                                      {droppableProvided.placeholder}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <div className={classes.addRowContainer}>
                                  <FanGoIconButton
                                    type={'add'}
                                    onClick={() => handleAddRow({ section })}
                                    size={'large'}
                                    disabled={false}
                                  />
                                </div>
                              </AccordionDetails>
                            )}
                          </Droppable>
                        </CustomAccordion>
                      )}
                    </Draggable>
                  );
                }
                return null;
              })}
              {containerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default EventPlanningTable;
