import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { cloneDeep, forEach, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const columns: {
  id: string;
  label: string;
  inputOptions?: { title: string; id: string }[];
}[] = [
  {
    id: 'emptyCellOne',
    label: '',
  },
  {
    id: 'timeUntilEvent',
    label: '',
  },
  {
    id: 'done',
    label: 'Done',
    inputOptions: [
      { title: 'Checkbox', id: 'checkbox' },
      { title: 'Textbox', id: 'textbox' },
    ],
  },
  {
    id: 'personResponsible',
    label: 'Person Responsible',
    inputOptions: [
      { title: 'Textbox', id: 'textbox' },
      { title: 'Checkbox', id: 'checkbox' },
    ],
  },
  {
    id: 'approxHoursToComplete',
    label: 'Approx. Hours To Complete',
    inputOptions: [
      { title: 'Textbox', id: 'textbox' },
      { title: 'Checkbox', id: 'checkbox' },
    ],
  },
  {
    id: 'edit',
    label: '',
  },
  {
    id: 'emptyCellTwo',
    label: '',
  },
];

export const processDragEnd = ({ result, sections, values }) => {
  const { destination, source, draggableId, type } = result;

  if (
    (destination?.index === source.index &&
      destination.droppableId === source.droppableId) ||
    !source ||
    !destination
  ) {
    return null;
  }

  if (type === 'outerDroppableContainer') {
    // Section to Section
    const idUnderscoreIndex = draggableId.indexOf('_') + 1;
    const dragId = draggableId.substring(idUnderscoreIndex, draggableId.length);
    const foundSection = sections.find((e) => e.id === dragId);
    const updateSection = cloneDeep(foundSection);
    const destinationIndex = destination.index;
    const sourceIndex = source.index;

    const updateSections = cloneDeep(sections);
    updateSections.splice(sourceIndex, 1);
    updateSections.splice(destinationIndex, 0, updateSection);

    return updateSections;
  }

  let sourceId = source.droppableId;
  const indexOfUnderscoreSource = sourceId.indexOf('_') + 1;
  const formattedSourceId = sourceId.substring(
    indexOfUnderscoreSource,
    sourceId.length
  );
  sourceId = formattedSourceId;

  let destinationId = destination.droppableId;
  const indexOfUnderscoreDestination = destinationId.indexOf('_') + 1;
  const formattedDestinationId = destinationId.substring(
    indexOfUnderscoreDestination,
    destinationId.length
  );
  destinationId = formattedDestinationId;

  // Same Table/Section
  if (sourceId === destinationId) {
    const foundSection = sections.find((e) => e.id === sourceId);
    const { sectionUids } = foundSection;
    const foundSectionIndex = sections.indexOf(foundSection);

    if (sectionUids.length) {
      const newOptionsOrder = [...sections];

      // Copy the found sections array of uids
      // Re-Order the array as needed
      const newSectionsOrder = [...sectionUids];
      newSectionsOrder.splice(source.index, 1);
      newSectionsOrder.splice(destination.index, 0, draggableId);

      // Copy the found section
      // Replace the cloned objects array with the re-ordered array
      const clonedFoundSection = cloneDeep(foundSection);
      clonedFoundSection.sectionUids = newSectionsOrder;

      // Replace the object at the index with the updated object
      newOptionsOrder.splice(foundSectionIndex, 1, clonedFoundSection);
      return newOptionsOrder;
    }
  }

  // Different Table/Section
  if (sourceId !== destinationId) {
    const newOptionsOrder = [...sections];
    const sourceSection = sections.find((e) => e.id === sourceId);
    const indexOfSourceSection = newOptionsOrder.indexOf(sourceSection);
    const destinationSection = sections.find((e) => e.id === destinationId);
    const indexOfDestinationSection =
      newOptionsOrder.indexOf(destinationSection);

    const sourceSectionArr = [...sourceSection.sectionUids];
    const sourceIndex = source.index;
    const destinationSectionArr = [...destinationSection.sectionUids];
    const destinationIndex = destination.index || 1;

    sourceSectionArr.splice(sourceIndex, 1);
    destinationSectionArr.splice(destinationIndex, 0, draggableId);

    const clonedSourceSection = cloneDeep(sourceSection);
    const clonedDestinationSection = cloneDeep(destinationSection);

    clonedSourceSection.sectionUids = sourceSectionArr;
    clonedDestinationSection.sectionUids = destinationSectionArr;

    newOptionsOrder.splice(indexOfSourceSection, 1, clonedSourceSection);
    newOptionsOrder.splice(
      indexOfDestinationSection,
      1,
      clonedDestinationSection
    );

    return newOptionsOrder;
  }

  return null;
};

export const handleDeleteRow = ({
  eventPlanningOptions,
  eventPlanningOptionsOrder,
  setEventPlanningOptions,
  setEventPlanningOptionsOrder,
  rowUid,
  setShowDialog,
  section,
  sections,
  setSections,
}) => {
  const newOptionsObj = { ...eventPlanningOptions };
  delete newOptionsObj[rowUid];

  const newOptionsArr = [...eventPlanningOptionsOrder];
  const index = newOptionsArr.indexOf(rowUid);
  newOptionsArr.splice(index, 1);

  const updateSection = cloneDeep(section);
  const { sectionUids } = updateSection;
  const deleteIndex = sectionUids.indexOf(rowUid);
  sectionUids.splice(deleteIndex, 1);

  const indexOfSection = sections.indexOf(section);
  const updateSections = cloneDeep(sections);
  updateSections.splice(indexOfSection, 1, updateSection);

  setSections(updateSections);
  setEventPlanningOptionsOrder(newOptionsArr);
  setEventPlanningOptions(newOptionsObj);

  setShowDialog(false);
};

export const handleDeleteSection = ({
  section,
  sections,
  eventPlanningOptions,
  eventPlanningOptionsOrder,
  setEventPlanningOptions,
  setEventPlanningOptionsOrder,
  setShowDialog,
  setSections,
}) => {
  const { sectionUids } = section;
  const updateOptionsArr = eventPlanningOptionsOrder.filter((optionUid) => {
    return !sectionUids.includes(optionUid);
  });

  const updateOptionsObj = cloneDeep(eventPlanningOptions);
  sectionUids.forEach((uid) => {
    delete updateOptionsObj[uid];
  });

  const indexOfSection = sections.indexOf(section);
  const updateSections = cloneDeep(sections);
  updateSections.splice(indexOfSection, 1);
  setSections(updateSections);

  setEventPlanningOptions(updateOptionsObj);
  setEventPlanningOptionsOrder(updateOptionsArr);

  setShowDialog(false);
};

export const getRowValues = ({ columns, rows, selectedInputOptions = {} }) => {
  const eventOptionsArr = [];
  const eventOptionsObj = {};

  rows.forEach((row) => {
    const { title: rowTitle, draggable, editable, titleRow, deletable } = row;

    const uid = uuidv4();
    eventOptionsArr.push(uid);

    const rowValues = {};
    columns.forEach((column) => {
      const { label, id, inputOptions } = column;
      if (inputOptions && !titleRow) {
        const obj = {};
        let activeInputOption = inputOptions?.[0]?.title;

        if (!isEmpty(selectedInputOptions)) {
          const foundOption = selectedInputOptions[id];
          if (foundOption) {
            const { value } = foundOption;
            activeInputOption = value;
          }
        }

        obj[`${id}InputOptions`] = inputOptions;
        obj[`${id}ActiveInputOption`] = activeInputOption || '';

        if (activeInputOption === 'Checkbox') {
          obj['value'] = false;
        } else if (activeInputOption === 'Textbox') {
          obj['value'] = '';
        }

        // rowValues[`${id}InputOptions`] = inputOptions;
        // rowValues[`${id}ActiveInputOption`] = inputOptions[0].title;
        rowValues[id] = obj;
      } else if (titleRow && !inputOptions) {
        rowValues['titleRow'] = true;
        rowValues['textColour'] = '#ffffff';
        rowValues['backgroundColour'] = '#2d9b83';

        const columnsCopy = cloneDeep(columns);
        columnsCopy.forEach((column) => {
          const { label } = column;
          if (label) {
            column.textColour = '#ffffff';
          }
          column.backgroundColour = '#141855';
        });
        rowValues['sectionColumns'] = columnsCopy;
      }
    });

    eventOptionsObj[uid] = {
      rowTitle,
      draggable,
      editable,
      deletable,
      ...rowValues,
    };
  });
  return { eventOptionsArr, eventOptionsObj };
};

type rowTypes = {
  draggable?: boolean;
  editable?: boolean;
  deletable?: boolean;
};

// export const getRowOptions = ({
//   title,
//   draggable = true,
//   editable = true,
//   deletable = true,
//   titleRow = false,
// }: rowTypes) => {
//   return { title, draggable, editable, deletable, titleRow };
// };

export const rowOptions = [
  { title: 'Draggable', id: 'draggable', value: true },
  { title: 'Editable', id: 'editable', value: true },
  { title: 'Deletable', id: 'deletable', value: true },
];
export const getRowOptions = () => {
  const options = {};
  rowOptions.forEach((option) => {
    const { id, value } = option;
    options[id] = value;
  });
  return options;
};

export const rows = [
  {
    title: '12 Months Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Define the event purpose and objectives',
    ...getRowOptions(),
  },
  {
    title: 'Set a budget for the event',
    ...getRowOptions(),
  },
  {
    title: 'Create a guest list and gather contact information',
    ...getRowOptions(),
  },
  {
    title: 'Research and select a venue',
    ...getRowOptions(),
  },
  {
    title: 'Check the availability of key attendees',
    ...getRowOptions(),
  },
  {
    title: 'Hire an event planner if needed',
    ...getRowOptions(),
  },
  {
    title: 'Begin researching and securing sponsers if applicable',
    ...getRowOptions(),
  },
  {
    title: 'Start mindmapping event themes and concepts',
    ...getRowOptions(),
  },
  {
    title: '9 Months Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Finalise the event date and time',
    ...getRowOptions(),
  },
  {
    title: 'Confirm venue details and requirements',
    ...getRowOptions(),
  },
  {
    title: 'Develop an event schedule',
    ...getRowOptions(),
  },
  {
    title: 'Begin designing event invitations',
    ...getRowOptions(),
  },
  {
    title: 'Explore catering options and book a caterer',
    ...getRowOptions(),
  },
  {
    title: 'Research and book entertainment or speakers',
    ...getRowOptions(),
  },
  {
    title: '6 Months Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Send out save-the-date notices',
    ...getRowOptions(),
  },
  {
    title: 'Confirm speakers or entertainment',
    ...getRowOptions(),
  },
  {
    title: 'Arrange transportation if necessary',
    ...getRowOptions(),
  },
  {
    title: 'Develop a marketing plan for the event',
    ...getRowOptions(),
  },
  {
    title: 'Begin promoting the event on social media',
    ...getRowOptions(),
  },
  {
    title: 'Create an event website or landing page',
    ...getRowOptions(),
  },
  {
    title: '3 Months Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Finalise the event schedule',
    ...getRowOptions(),
  },
  {
    title: 'Send out formal invitations',
    ...getRowOptions(),
  },
  {
    title: 'Confirm catering details and menu',
    ...getRowOptions(),
  },
  {
    title: 'Arrange for event decorations',
    ...getRowOptions(),
  },
  {
    title: 'Coordinate with sponsers and vendors',
    ...getRowOptions(),
  },
  {
    title: 'Implement the marketing plan',
    ...getRowOptions(),
  },
  {
    title: '1 Month Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Confirm RSVPs and guest accommodations',
    ...getRowOptions(),
  },
  {
    title: 'Finalise seating arrangements',
    ...getRowOptions(),
  },
  {
    title: 'Reconfirm all vendor contracts',
    ...getRowOptions(),
  },
  {
    title: 'Conduct a final walkthrough of the venue',
    ...getRowOptions(),
  },
  {
    title: 'Prepare event materials and signage',
    ...getRowOptions(),
  },
  {
    title: 'Test audio-visual equipment',
    ...getRowOptions(),
  },
  {
    title: '1 Week Before the Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Confirm final guest count',
    ...getRowOptions(),
  },
  {
    title: 'Pack an event-day emergency kit',
    ...getRowOptions(),
  },
  {
    title: 'Confirm transportation details',
    ...getRowOptions(),
  },
  {
    title: 'Provide final details to all staff and volunteers',
    ...getRowOptions(),
  },
  {
    title: 'Set up event space and decorations',
    ...getRowOptions(),
  },
  {
    title: 'Conduct a final run-through of the schedule',
    ...getRowOptions(),
  },
  {
    title: 'Event Day',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Arrive early to oversee setup',
    ...getRowOptions(),
  },
  {
    title: 'Greet and check in attendees',
    ...getRowOptions(),
  },
  {
    title: 'Manage the event schedule and logistics',
    ...getRowOptions(),
  },
  {
    title: 'Monitor catering and refreshments',
    ...getRowOptions(),
  },
  {
    title: 'Capture event photos for social media',
    ...getRowOptions(),
  },
  {
    title: 'Thank attendees and sponsors',
    ...getRowOptions(),
  },
  {
    title: 'Post-Event',
    ...getRowOptions(),
    titleRow: true,
  },
  {
    title: 'Send thank-you notes to attendees and sponsors',
    ...getRowOptions(),
  },
  {
    title: 'Gather feedback from participants',
    ...getRowOptions(),
  },
  {
    title: 'Evaluate the success of the event against objectives',
    ...getRowOptions(),
  },
  {
    title: 'Update the website and social media with event highlights',
    ...getRowOptions(),
  },
  {
    title: 'Begin planning for future events',
    ...getRowOptions(),
  },
];

export const getColumnsArr = ({ columns }) => {
  const colsArr = [];
  forEach(columns, (value, key) => {
    const { label } = value;
    if (label) {
      colsArr.push(value);
    }
  });
  return colsArr;
};

// export const handleCheckboxUpdate = ({ options, checkboxId, setOptions }) => {
//   const foundOption = options.find((option) => option.id === checkboxId);
//   const updateOption = cloneDeep(foundOption);
//   const index = options.indexOf(foundOption);
//   const { value } = foundOption;
//   updateOption.value = !value;
//   const updateOptions = [...options];
//   updateOptions.splice(index, 1, updateOption);
//   setOptions(updateOptions);
// };

// export const rowOptions = { draggable: true, editable: true, deletable: true };

export const getTableSections = ({ optionsArr, optionsObj }) => {
  const sections = [];
  let section = [];
  let titleRowAdded = false;
  let carryOverTitleRowUid = '';
  let title = '';
  let titleRowUid = '';

  for (let i = 0; i < optionsArr.length; i++) {
    if (carryOverTitleRowUid) {
      section.push(carryOverTitleRowUid);
      carryOverTitleRowUid = '';
    }

    const uid = optionsArr[i];
    const foundOption = optionsObj[uid];
    const { titleRow, rowTitle } = foundOption;

    if (!titleRow) {
      section.push(uid);
    } else if (titleRow && !titleRowAdded) {
      section.push(uid);
      titleRowAdded = true;
      title = rowTitle;
      titleRowUid = uid;
    } else if (titleRow && titleRowAdded) {
      sections.push({
        title,
        sectionUids: section,
        id: titleRowUid,
      });
      titleRowUid = uid;
      carryOverTitleRowUid = uid;
      section = [];
      title = optionsObj[uid].rowTitle;
    }
    if (i === optionsArr.length - 1) {
      if (section.length === 0) {
        section.push(carryOverTitleRowUid);
      }
      sections.push({
        title,
        sectionUids: section,
        id: titleRowUid,
      });
    }
  }
  return sections;
};

export const planSetup = ({
  setEventPlanningOptionsOrder,
  setEventPlanningOptions,
  setInitialEventOptionsObj,
  setInitialEventOptionsArr,
  setInitialFormValues,
  setSections,
}) => {
  useEffect(() => {
    const { eventOptionsObj, eventOptionsArr } = getRowValues({
      columns,
      rows,
    });
    setEventPlanningOptionsOrder(eventOptionsArr);
    setEventPlanningOptions(eventOptionsObj);

    setInitialEventOptionsObj(cloneDeep(eventOptionsObj));
    setInitialEventOptionsArr(cloneDeep(eventOptionsArr));

    const columnsObj = {};
    columns.forEach((column) => {
      const { id, label, inputOptions } = column;
      columnsObj[id] = { id, label, inputOptions };
    });

    const formVals = { ...eventOptionsObj, columns: columnsObj };
    setInitialFormValues(formVals);

    const tableSections = getTableSections({
      optionsArr: eventOptionsArr,
      optionsObj: eventOptionsObj,
    });
    setSections(tableSections);
  }, []);
};

export const handleScrollToTitle = ({ title }) => {
  const element = document.getElementById(title);
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};

export const handleHexInput = ({
  value,
  selectedColour,
  setSelectedColour,
}) => {
  const newValue = value.substring(1, value.length);
  const allowedInput = /^[A-Za-z0-9]*$/.test(newValue);
  const formattedValue = `#${newValue}`;

  if ((selectedColour === '#' && formattedValue === '#') || !allowedInput) {
    return null;
  }

  setSelectedColour(formattedValue.toUpperCase());
};

// replace this
export const checkColour = ({ currentColour = '', selectedColour = '' }) => {
  console.log(currentColour);
  console.log(selectedColour);
  let colour = currentColour;
  if (
    (currentColour.length === 1 && currentColour[0] === '#') ||
    (selectedColour.length === 1 && selectedColour[0] === '#')
  ) {
    colour = '#000000';
  }
  return colour;
};
