import { useFormikContext } from 'formik';
import React from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../common/button/FanGoIconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: theme.spacing(1.5),
    },
    dragIconContainer: {
      display: 'flex',
    },
    accordionSummaryContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    editAndDeleteContainer: {
      display: 'flex',
      columnGap: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
  })
);

const AccordionSummaryContentAndButtons = ({
  provided,
  title,
  handleShowDialog,
  id,
  handleDeleteSectionClick,
  section,
  textColour,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.accordionSummaryContentContainer}>
      <div className={classes.accordionTitleContainer}>
        <div
          {...provided.dragHandleProps}
          className={classes.dragIconContainer}
          style={{ color: textColour }}
        >
          <DragIndicatorIcon />
        </div>
        <Typography variant="h4" sx={{ mr: 1, color: textColour }}>
          {title}
        </Typography>
      </div>
      <div className={classes.editAndDeleteContainer}>
        <FanGoIconButton
          type={'edit'}
          onClick={() =>
            handleShowDialog({
              id,
              type: 'editTitleRow',
            })
          }
          size={'medium'}
          disabled={false}
        />
        <FanGoIconButton
          type={'delete'}
          onClick={() =>
            handleDeleteSectionClick({
              id,
              type: 'deleteSection',
              section,
            })
          }
          size={'medium'}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default AccordionSummaryContentAndButtons;
