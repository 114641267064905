import React from 'react';

import { Box } from '@mui/material';

const DividerLine = () => {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
        height: '1px',
        backgroundColor: '#D3D3D3',
        width: '100%',
      }}
    />
  );
};

export default DividerLine;
