import React, { useContext, useEffect, useState } from 'react';

import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { DataContext } from '../../../providers/DataProvider';
import Body from '../../common/body/Body';
import FanGoIconButton from '../../common/button/FanGoIconButton';
import CustomFanGoDialog from '../../common/dialog/CustomFanGoDialog';
import styles from '../../common/styling/styles';
import CustomDialogContent from './dialogContent/CustomDialogContent';
import EventPlanningTable from './EventPlanningTable';
import PlanInteractionOptions from './PlanInteractionOptions';
import { columns } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'pink',
    },
    customisationOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    addRowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    downloadAndReviewContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const SoftwareSection = ({
  eventPlanningOptionsOrder,
  setEventPlanningOptionsOrder,
  eventPlanningOptions,
  setEventPlanningOptions,
  initialEventOptionsArr,
  initialEventOptionsObj,
  sections,
  setSections,
  planReset,
  setPlanReset,
  values,
  resetForm,
  getEventPlanPDF,
  logo,
}) => {
  const [quickRowDeletionEnabled, setQuickRowDeletionEnabled] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');
  const { setContentLoading } = useContext(DataContext);

  const classes = useStyles();

  const handleShowDialog = ({ type }) => {
    setDialogContentType(type);
    setShowDialog(true);
  };

  const handleAddSection = () => {
    handleShowDialog({ type: 'add' });
  };

  useEffect(() => {
    setContentLoading(false);
  }, []);
  return (
    <Body paddingY={styles.allFive}>
      <>
        {showDialog && (
          <CustomFanGoDialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
          >
            <CustomDialogContent
              type={dialogContentType}
              columns={columns}
              setShowDialog={setShowDialog}
              eventPlanningOptionsOrder={eventPlanningOptionsOrder}
              setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
              eventPlanningOptions={eventPlanningOptions}
              setEventPlanningOptions={setEventPlanningOptions}
              sections={sections}
              setSections={setSections}
              addSection={true}
              setPlanReset={setPlanReset}
            />
          </CustomFanGoDialog>
        )}
        <Typography
          color="#141855"
          variant="h2"
          align="center"
          sx={{ mb: 4, scrollMargin: '75px', pr: 2 }}
          id="Event Planning Checklist"
        >
          Download FanGo's Event Planning Checklist
        </Typography>
        <PlanInteractionOptions
          quickRowDeletionEnabled={quickRowDeletionEnabled}
          setQuickRowDeletionEnabled={setQuickRowDeletionEnabled}
          setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
          setEventPlanningOptions={setEventPlanningOptions}
          initialEventOptionsArr={initialEventOptionsArr}
          initialEventOptionsObj={initialEventOptionsObj}
          setSections={setSections}
          setPlanReset={setPlanReset}
          resetForm={resetForm}
          setShowDialog={setShowDialog}
          setDialogContentType={setDialogContentType}
          getEventPlanPDF={getEventPlanPDF}
          values={values}
          sections={sections}
          showDialog={showDialog}
          dialogContentType={dialogContentType}
          logo={logo}
        />
        <Box sx={{ mt: 2, mr: 2 }}>
          <EventPlanningTable
            eventPlanningOptionsOrder={eventPlanningOptionsOrder}
            setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
            setEventPlanningOptions={setEventPlanningOptions}
            eventPlanningOptions={eventPlanningOptions}
            columns={columns}
            quickRowDeletionEnabled={quickRowDeletionEnabled}
            sections={sections}
            setSections={setSections}
            planReset={planReset}
            setPlanReset={setPlanReset}
          />
        </Box>
        <div className={classes.addRowContainer}>
          <FanGoIconButton
            type={'add'}
            onClick={handleAddSection}
            size={'large'}
            backgroundColor="rgb(45,155,131)"
            disabled={false}
            htmlColor="white"
          />
        </div>
      </>
    </Body>
  );
};

export default SoftwareSection;
