import React from 'react';

import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Body from '../../common/body/Body';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import FullImageBackgroundHeader from '../CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const PlanYourEventHeadSection = ({
  title,
  subTitleText,
  getEventPlanPDF,
  sections,
  formRef,
  logo,
}) => {
  const classes = useStyles();

  const handleDownloadEventPlan = () => {
    const { values } = formRef.current;
    getEventPlanPDF({ values, sections, logo });
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            borderRadius: 5,
            backgroundColor: 'rgb(45,155,131)',
            width: '200px',
            height: '75px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.4)',
            padding: '16px',
            cursor: 'pointer',
          }}
          onClick={handleDownloadEventPlan}
        >
          <Typography fontWeight="bold" color="white" textAlign="center">
            Download Your Free Event Plan
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default PlanYourEventHeadSection;
