import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import DialogTitle from '../../../common/dialog/DialogTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        minWidth: '600px',
      },
      width: '100%',
    },
  })
);

const DialogContentContainer = ({ dialogTitle, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <DialogTitle titleText={dialogTitle} />
      {children}
    </div>
  );
};

export default DialogContentContainer;
