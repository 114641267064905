import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Typography } from '@mui/material';

const iconBox = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const iconContainer = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: '10px',
};

export const getIcon = ({ listItemType }) => {
  switch (listItemType) {
    case 'bulletPoint':
      return (
        <div style={{ ...iconBox, fontSize: '10px' }}>
          <FiberManualRecordIcon fontSize="inherit" />
        </div>
      );

    case 'checkbox':
      return (
        <div style={{ ...iconBox, fontSize: '30px' }}>
          <CheckBoxOutlineBlankIcon fontSize="inherit" />
        </div>
      );
  }
};

export const formatLists = ({ lists }) => {
  const formattedLists = [];
  lists.forEach((list) => {
    const { listPosition, listItems } = list;
    const itemsList = listItems.map((listItem) => {
      const { listItemName, listItemType } = listItem;
      const Icon = getIcon({ listItemType });
      const uid = uuidv4();
      return (
        <div key={uid} style={iconContainer}>
          {Icon}
          <Typography color="black">{listItemName}</Typography>
        </div>
      );
    });

    formattedLists.push({ itemsList, listPosition });
  });
  return formattedLists;
};

export const checkAndRenderList = ({ formattedLists, index }) => {
  const foundList = formattedLists.find((list) => {
    return list.listPosition === index;
  });
  if (foundList) {
    const { itemsList } = foundList;
    const uid = uuidv4();
    return <div key={uid}>{itemsList.map((listItem) => listItem)}</div>;
  }
  return null;
};

export const sortByPosition = (a, b) => {
  return a.listPosition - b.listPosition;
};

export const getEndOfTextLists = ({ formattedLists, textBlocksLength }) => {
  const listsToBeRenderedAtTheEnd = formattedLists.filter((list) => {
    return list.listPosition >= textBlocksLength;
  });

  const formattedEndLists = [];
  if (listsToBeRenderedAtTheEnd.length > 1) {
    const sortedList = listsToBeRenderedAtTheEnd.sort(sortByPosition);
    sortedList.forEach((item) => {
      formattedEndLists.push(item);
    });
  } else if (listsToBeRenderedAtTheEnd.length === 1) {
    listsToBeRenderedAtTheEnd[0].listPosition = textBlocksLength;
    const item = listsToBeRenderedAtTheEnd[0];
    item.listPosition === formattedLists.length;
    formattedEndLists.push(item);
  }

  return listsToBeRenderedAtTheEnd;
};

export const formatSubTitle = ({ subTitle }) => {
  if (!subTitle) {
    return null;
  }
  let formattedSubTitle = subTitle;
  if (subTitle[subTitle.length - 1] === ':') {
    const subTitleCopy = subTitle;
    const newSubTitle = subTitleCopy.substring(0, subTitleCopy.length - 1);
    formattedSubTitle = newSubTitle;
  }
  return formattedSubTitle;
};
