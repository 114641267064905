import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import FanGoIconButton from '../../../common/button/FanGoIconButton';
import SubmitButton from '../../../common/button/SubmitButton';
import ColourPicker from '../../../common/colourPicker/ColourPicker';
import DialogContentContainer from './DialogContentContainer';

const EditColumnsDialogContent = ({ setShowDialog, section, sections }) => {
  const { handleChange, values } = useFormikContext();

  const { id } = section;
  const { sectionColumns } = values[id];

  const foundColumn = sectionColumns.find((column) => column.textColour);
  const { textColour, backgroundColour } = foundColumn;

  const [selectedTextColour, setSelectedTextColour] = useState(textColour);

  const [selectedBackgroundColour, setSelectedBackgroundColour] =
    useState(backgroundColour);

  const [clonedColumns, setClonedColumns] = useState(cloneDeep(sectionColumns));
  const [applyTitlesToAllColumns, setApplyTitlesToAllColumns] = useState(false);
  const [applyTextColourToAllSections, setApplyTextColourToAllSections] =
    useState(false);
  const [
    applyBackgroundColourToAllSections,
    setApplyBackgroundColourToAllSections,
  ] = useState(false);

  const handleEditSubmit = () => {
    const targetId = `${id}.sectionColumns`;
    clonedColumns.forEach((column) => {
      const { textColour } = column;
      if (textColour) {
        column.textColour = selectedTextColour;
      }
      column.backgroundColour = selectedBackgroundColour;
    });

    if (
      applyTitlesToAllColumns ||
      applyTextColourToAllSections ||
      applyBackgroundColourToAllSections
    ) {
      sections.forEach((section) => {
        const { id } = section;
        const targetId = `${id}.sectionColumns`;
        const updateSectionColumns = cloneDeep(values[id].sectionColumns);
        updateSectionColumns.forEach((column) => {
          if (applyTitlesToAllColumns) {
            const { id: colId } = column;
            const foundUpdateColumn = clonedColumns.find(
              (col) => col.id === colId
            );
            if (foundUpdateColumn.label) {
              column.label = foundUpdateColumn.label;
            }
          }
          if (applyTextColourToAllSections) {
            column.textColour = selectedTextColour;
          }
          if (applyBackgroundColourToAllSections) {
            column.backgroundColour = selectedBackgroundColour;
          }
        });
        handleChange({
          target: { id: targetId, value: updateSectionColumns },
        });
      });
    } else {
      handleChange({
        target: { id: targetId, value: clonedColumns },
      });
    }

    setShowDialog(false);
  };

  const currentColumnTitles = [];
  sectionColumns.forEach((column) => {
    const { label } = column;
    if (label) {
      currentColumnTitles.push(label);
    }
  });

  const editOptions = [
    {
      selectedColour: selectedTextColour,
      setSelectedColour: setSelectedTextColour,
      textValue: currentColumnTitles,
      editTextTitle: 'Edit Text Colour',
    },
    {
      selectedColour: selectedBackgroundColour,
      setSelectedColour: setSelectedBackgroundColour,
      textValue: '',
      editTextTitle: 'Edit Section Title Background Colour',
    },
  ];

  return (
    <DialogContentContainer dialogTitle={'Edit Columns'}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Columns
      </Typography>
      {sectionColumns.map((column, index) => {
        const { id, label } = column;
        if (!label) {
          return null;
        }
        return (
          <Grid key={id} container sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                size="small"
                value={clonedColumns[index].label}
                onChange={(e): void => {
                  const value = e.target.value;
                  const newColumnValues = [...clonedColumns];
                  newColumnValues[index].label = value;
                  setClonedColumns(newColumnValues);
                }}
              />
            </Grid>
          </Grid>
        );
      })}

      {editOptions.map((option) => {
        const { selectedColour, setSelectedColour, textValue, editTextTitle } =
          option;
        return (
          <ColourPicker
            key={editTextTitle}
            selectedColour={selectedColour}
            setSelectedColour={setSelectedColour}
            text={textValue}
            editTextTitle={editTextTitle}
          />
        );
      })}

      <Box sx={{ maxWidth: '600px' }}>
        <FormControlLabel
          control={
            <Checkbox
              value={applyTitlesToAllColumns}
              onChange={() =>
                setApplyTitlesToAllColumns(!applyTitlesToAllColumns)
              }
            />
          }
          label={'Apply column Title Text changes to all sections'}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={applyTextColourToAllSections}
              onChange={() =>
                setApplyTextColourToAllSections(!applyTextColourToAllSections)
              }
            />
          }
          label={'Apply column Text Colour changes to all sections'}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={applyBackgroundColourToAllSections}
              onChange={() =>
                setApplyBackgroundColourToAllSections(
                  !applyBackgroundColourToAllSections
                )
              }
            />
          }
          label={'Apply column Background Colour changes to all sections'}
        />
      </Box>

      <Typography sx={{ fontWeight: 'bold' }}>Preview</Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 3,
          backgroundColor: selectedBackgroundColour,
          p: 1,
          maxWidth: '600px',
        }}
      >
        {sectionColumns.map((column) => {
          const {
            id,
            label = '',
            textColour = '',
            backgroundColour = '',
          } = column;

          console.log(label);

          let displayValue = label;
          if (id === 'edit') {
            return (
              <Box
                key={id}
                sx={{
                  color: textColour,
                  textAlign: 'center',
                  height: '100%',
                  width: '10%',
                }}
              >
                <FanGoIconButton
                  type="edit"
                  size="medium"
                  onClick={() => {}}
                  disabled={false}
                />
              </Box>
            );
          }

          return (
            <div key={id}>
              {label && (
                <Box
                  sx={{
                    color: textColour,
                    textAlign: 'center',
                    width: '100px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '15px!important',
                      color: selectedTextColour,
                    }}
                  >
                    {displayValue}
                  </Typography>
                </Box>
              )}
              {!label && (
                <Box
                  sx={{
                    color: textColour,
                    textAlign: 'center',
                    backgroundColor: backgroundColour,
                    width: '50px',
                  }}
                />
              )}
            </div>
          );
        })}
      </Box>

      <SubmitButton
        onClick={handleEditSubmit}
        disabled={
          selectedTextColour.length <= 3 || selectedBackgroundColour.length <= 3
        }
      />
    </DialogContentContainer>
  );
};

export default EditColumnsDialogContent;
