import hexRgb from 'hex-rgb';
import React from 'react';
import { HexColorPicker } from 'react-colorful';

import { Box, Grid, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { handleHexInput } from '../../PageComps/PlanYourEventPageComps/utils';
import SubmitButton from '../button/SubmitButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: '600px',
      minWidth: '420px',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      borderRadius: 10,
    },

    title: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    titleAndColourInputContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      overflow: 'hidden',
      width: '100%',
    },
    contentsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);

const renderText = ({ value, colour }) => {
  return (
    <Typography
      sx={{
        textShadow: '1px 1px rgba(150, 150, 150, 0.5)',
      }}
      maxWidth={200}
      color={colour}
      textAlign="center"
      key={value}
    >
      {value}
    </Typography>
  );
};

const ColourPicker = ({
  selectedColour,
  setSelectedColour,
  editTextTitle,
  text = null,
  onEditSubmit = null,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentsContainer}>
        <HexColorPicker
          style={{ minWidth: '40%' }}
          color={selectedColour}
          onChange={(value) => setSelectedColour(value.toUpperCase())}
        />
        <div className={classes.titleAndColourInputContainer}>
          <Typography variant="h5" className={classes.title}>
            {editTextTitle}
          </Typography>
          {Array.isArray(text) &&
            !!text.length &&
            text.map((textValue) => {
              return renderText({ value: textValue, colour: selectedColour });
            })}
          {!Array.isArray(text) &&
            text &&
            renderText({ value: text, colour: selectedColour })}
          {!text && (
            <Box
              sx={{
                width: '70px',
                height: '70px',
                backgroundColor: selectedColour,
                border: '1px solid lightgray',
              }}
            />
          )}
          <TextField
            inputProps={{ maxLength: 7 }}
            variant="outlined"
            size="small"
            value={selectedColour.toUpperCase()}
            onChange={(e) => {
              const value = e.target.value;
              handleHexInput({
                value,
                selectedColour: selectedColour,
                setSelectedColour: setSelectedColour,
              });
            }}
          />
        </div>
      </div>
      <Box sx={{ mt: 1, mb: 1, height: '12px' }}>
        {selectedColour.length <= 3 && (
          <Typography
            style={{
              color: 'red',
              fontStyle: 'italic',
              margin: 0,
              padding: 0,
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            Please enter at least 3 numbers/letters to create a valid colour
          </Typography>
        )}
      </Box>
      {onEditSubmit && <SubmitButton onClick={onEditSubmit} />}
    </div>
  );
};

export default ColourPicker;
