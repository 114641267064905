import { useFormikContext } from 'formik';
import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../../common/button/FanGoIconButton';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const handleEditColumnTitles = ({
  section,
  setCurrentSection,
  handleShowDialog,
}) => {
  setCurrentSection(section);
  handleShowDialog({ type: 'editColumns' });
};

const EventPlanningTableHead = ({
  sectionColumns,
  setCurrentSection,
  handleShowDialog,
  section,
}) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {sectionColumns.map((column) => {
          const {
            id,
            label = '',
            textColour = '',
            backgroundColour = '',
          } = column;

          let displayValue = label;
          if (id === 'edit') {
            return (
              <TableCell
                key={id}
                sx={{
                  color: textColour,
                  textAlign: 'center',
                  backgroundColor: backgroundColour,
                }}
              >
                <FanGoIconButton
                  type="edit"
                  size="medium"
                  onClick={() =>
                    handleEditColumnTitles({
                      section,
                      setCurrentSection,
                      handleShowDialog,
                    })
                  }
                  disabled={false}
                />
              </TableCell>
            );
          }

          return (
            <TableCell
              key={id}
              sx={{
                color: textColour,
                textAlign: 'center',
                backgroundColor: backgroundColour,
              }}
            >
              {displayValue}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EventPlanningTableHead;
