import { useFormikContext } from 'formik';
import React from 'react';

import { Checkbox, TextField } from '@mui/material';

type tableCellOptions = {
  rowUid: string;
  columnId: string;
  inputOption: string;
};

const TableCellInputs = ({
  inputOption,
  rowUid,
  columnId,
}: tableCellOptions) => {
  const { handleChange, values } = useFormikContext();
  const id = `${rowUid}.${columnId}.value`;

  const value = !!values?.[rowUid]?.[columnId]?.value;

  switch (inputOption) {
    case 'Checkbox':
      return (
        <Checkbox
          id={id}
          checked={value}
          onChange={(e) => {
            handleChange({
              target: {
                id,
                value: !values?.[rowUid]?.[columnId]?.value,
              },
            });
          }}
        />
      );
    case 'Textbox':
      return (
        <TextField
          variant="outlined"
          size="small"
          id={id}
          value={values?.[rowUid]?.[columnId]?.value || ''}
          onChange={(e): void => {
            handleChange({ target: { id, value: e.target.value } });
          }}
        />
      );
  }

  return null;
};

export default TableCellInputs;
