import { Formik } from 'formik';
import React from 'react';

import SoftwareSection from './SoftwareSection';

const EventPlan = ({
  initialFormValues,
  formRef,
  eventPlanningOptions,
  setEventPlanningOptions,
  eventPlanningOptionsOrder,
  setEventPlanningOptionsOrder,
  sections,
  setSections,
  planReset,
  setPlanReset,
  initialEventOptionsObj,
  initialEventOptionsArr,
  getEventPlanPDF,
  logo,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={() => {}}
      innerRef={formRef}
    >
      {({ values, resetForm }) => (
        <>
          {values && (
            <SoftwareSection
              eventPlanningOptions={eventPlanningOptions}
              setEventPlanningOptions={setEventPlanningOptions}
              eventPlanningOptionsOrder={eventPlanningOptionsOrder}
              setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
              sections={sections}
              setSections={setSections}
              planReset={planReset}
              setPlanReset={setPlanReset}
              initialEventOptionsObj={initialEventOptionsObj}
              initialEventOptionsArr={initialEventOptionsArr}
              values={values}
              resetForm={resetForm}
              getEventPlanPDF={getEventPlanPDF}
              logo={logo}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default EventPlan;
