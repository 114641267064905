import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DataContext } from '../../../providers/DataProvider';
import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import PlanYourEventContentSection from './PlanYourEventContentSection';
import { formatSubTitle } from './planYourEventContentUtils';

const observerCallback = ({ entries, setCurrentScrollSection }) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const section = entry.target.id;
      setCurrentScrollSection(section);
    }
  });
};
const PlanYourEventTextContent = ({ paragraphs }) => {
  const { setCurrentScrollSection, contentLoading } = useContext(DataContext);
  const [sections, setSections] = useState([]);
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    setObserver(
      new IntersectionObserver(
        (entries) => observerCallback({ entries, setCurrentScrollSection }),
        {
          rootMargin: '0px',
          threshold: 0.5,
        }
      )
    );
  }, []);

  useEffect(() => {
    if (!contentLoading && observer) {
      sections.forEach((item) => {
        const { props } = item;
        const { id } = props;
        const foundBlock = document.getElementById(id);
        if (foundBlock) {
          observer.observe(foundBlock);
        }
      });

      const titleSection = document.getElementById('Event Planning Checklist');
      if (titleSection) {
        observer.observe(titleSection);
      }
    }
  }, [contentLoading, observer]);

  if (sections.length === 0) {
    const arr = [];
    paragraphs.forEach((section) => {
      const { title, subTitle } = section;
      const subTitleId = formatSubTitle({ subTitle });
      const containerId = subTitleId || title;
      const uid = uuidv4();
      const block = (
        <PlanYourEventContentSection
          key={uid}
          section={section}
          id={containerId}
        />
      );
      arr.push(block);
    });
    setSections(arr);
  }

  return (
    <>
      <Body paddingY={styles.allFive}>
        {sections.map((section) => section)}
      </Body>
    </>
  );
};

export default PlanYourEventTextContent;
