import React from 'react';

import { Cancel } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconButton from '../button/FanGoIconButton';
import styles from '../styling/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    dialogContentContainer: {
      position: 'relative',
    },
  })
);

const CustomFanGoDialog = ({ open, onClose, children }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth={'md'}>
      <DialogContent
        style={{ padding: 20 }}
        className={classes.dialogContentContainer}
        sx={{ maxWidth: '800px' }}
      >
        <div className={classes.iconContainer}>
          <FanGoIconButton
            type="cancel"
            onClick={onClose}
            size={'medium'}
            disabled={false}
          />
        </div>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomFanGoDialog;
