import { graphql } from 'gatsby';
import fileDownload from 'js-file-download';
import React, { createContext, useContext, useRef, useState } from 'react';

import { Box, Grid, Hidden, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { pdf } from '@react-pdf/renderer';

import StandardSEO from '../components/common/seo/StandardSEO';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import TitleAndContent from '../components/PageComps/CommonComps/heroSections/heroSectionComps/TitleAndContent';
import ContentsContainer from '../components/PageComps/PlanYourEventPageComps/ContentsContainer';
import EventPlan from '../components/PageComps/PlanYourEventPageComps/EventPlan';
import PlanYourEventHeadSection from '../components/PageComps/PlanYourEventPageComps/PlanYourEventHeadSection';
import PlanYourEventTextContent from '../components/PageComps/PlanYourEventPageComps/PlanYourEventTextContent';
import Table from '../components/PageComps/PlanYourEventPageComps/reactPdfComps/Table';
import { planSetup } from '../components/PageComps/PlanYourEventPageComps/utils';
import { DataContext as MainDataContext } from '../providers/DataProvider';

const getEventPlanPDF = async ({ values, sections, logo }) => {
  const myDocument = Table({ values, sections, logo });
  const documentBlob = await pdf(myDocument).toBlob();
  fileDownload(documentBlob, 'FanGoEventPlan.pdf');

  if (window && window.dataLayer) {
    window.dataLayer.push({ event: 'planyourevent_success' });
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contents: {
      [theme.breakpoints.down(1000)]: {
        display: 'none',
      },
      display: 'grid',
    },
  })
);

export const DataContext = createContext(null);

const PlanYourEventPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(MainDataContext);
  const classes = useStyles();
  const { sanityPlanYourEventPage } = data;
  const { planYourEventPageHead, paragraphs } = sanityPlanYourEventPage;
  const { title, _rawBody, image: logo } = planYourEventPageHead;
  const [eventPlanningOptionsOrder, setEventPlanningOptionsOrder] = useState(
    []
  );
  const [eventPlanningOptions, setEventPlanningOptions] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(null);
  const [sections, setSections] = useState([]);
  const [planReset, setPlanReset] = useState(false);

  const [initialEventOptionsObj, setInitialEventOptionsObj] = useState(null);
  const [initialEventOptionsArr, setInitialEventOptionsArr] = useState(null);
  const formRef = useRef();

  planSetup({
    setEventPlanningOptionsOrder,
    setEventPlanningOptions,
    setInitialEventOptionsObj,
    setInitialEventOptionsArr,
    setInitialFormValues,
    setSections,
  });

  // move the highlighted title to stop the rerendering

  console.log(_rawBody);

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="30% 10%"
        imageData={imageData}
        maxHeight="880px"
        content={
          <TitleAndContent
            mobileHeight="880px"
            desktopHeight="700px"
            shadeValue="0.5"
            title={title}
            subTitle={_rawBody}
            largeSubTitleSize
            hideSubTitleOnMobile
            content={
              <PlanYourEventHeadSection
                title={title}
                subTitleText={_rawBody}
                getEventPlanPDF={getEventPlanPDF}
                formRef={formRef}
                sections={sections}
                logo={logo}
              />
            }
          />
        }
      />

      <Box sx={{ width: '100%', backgroundColor: 'white' }}>
        <Box sx={{ maxWidth: '1500px', margin: 'auto' }}>
          <Grid container spacing={5} sx={{ backgroundColor: 'white' }}>
            <Hidden mdDown>
              <Grid item md={4} lg={3}>
                <ContentsContainer paragraphs={paragraphs} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={8} lg={9}>
              <EventPlan
                initialFormValues={initialFormValues}
                eventPlanningOptions={eventPlanningOptions}
                setEventPlanningOptions={setEventPlanningOptions}
                eventPlanningOptionsOrder={eventPlanningOptionsOrder}
                setEventPlanningOptionsOrder={setEventPlanningOptionsOrder}
                sections={sections}
                setSections={setSections}
                planReset={planReset}
                setPlanReset={setPlanReset}
                initialEventOptionsObj={initialEventOptionsObj}
                initialEventOptionsArr={initialEventOptionsArr}
                getEventPlanPDF={getEventPlanPDF}
                formRef={formRef}
                logo={logo}
              />
              <PlanYourEventTextContent paragraphs={paragraphs} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PlanYourEventPage;
export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityPlanYourEventPage.seo} />
);

export const query = graphql`
  query {
    sanityPlanYourEventPage {
      seo {
        title
        description
        keywords
        name
        type
      }
      planYourEventPageHead {
        title
        _rawBody
        image {
          asset {
            url
          }
        }
      }
      paragraphs {
        title
        subTitle
        _rawBody
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 1200, formats: [WEBP])
          }
          altText
        }

        lists {
          listPosition
          listItems {
            listItemName
            listItemType
          }
        }
      }
    }
  }
`;
